import { useEffect, useState } from "react";
import { CModalTwo } from "../../mui/ModalTwo";
import { LoginChildSec } from "../../newStructures/LoginChild.style";
import { CButton } from "../../mui";
import { CButtonTwo } from "../../mui/ButtonTwo";
import { ProfileModel } from "../../../models/profile.model";
import { useDispatch } from "react-redux";
import { useGlobalContext, useGlobalStyleContext } from '../../../contexts'
import { set } from "../../../redux/slices/user";
import { useNavigate } from "react-router-dom";

interface Props {
  color?: string;
  textColor?: string;
  size?: "s" | "m" | "l";
  setLoginState: (loginState: boolean) => void;
  tempUser: ProfileModel;
}

export function LastWelcomePart(props: Props) {
  const { color, textColor, size, setLoginState, tempUser } = props;
  const { makeAlert } = useGlobalStyleContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const finalStartExplore = () => {
    if (tempUser) {
      dispatch(set(tempUser));
      makeAlert("success", "Register account successfully done");
    }
    setLoginState(false);
  };
  const finalToProfile = () => {
    if (tempUser) {
      dispatch(set(tempUser));
      makeAlert("success", "Register account successfully done");
    }
    setLoginState(false);
    navigate("/profile");
  };

  return (
    <>
      {/* <CModalTwo
        open={loginState}
        onClose={closeLoginModal}
        title="login modal"
        width="540"
      > */}
      <LoginChildSec minHeight="540px">
        <div className="top-heading">
          <h3 className="title">Welcome To Personia!</h3>
          <div className="subtitle">
            You've successfully logged in. Your journey in Personia begins now.
            Let’s get started!
          </div>
        </div>
        <div className="loading preWelcome">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              gap: "2rem",
            }}
          >
            <CButtonTwo
              fullWidth
              size={"s"}
              background={color}
              backgroundHover={color}
              color={textColor}
              loadingColor={textColor}
              onClick={finalStartExplore}
              height={"40px"}
              fontSize="14px"
            >
              Start Exploring
            </CButtonTwo>
            <CButtonTwo
              fullWidth
              size={"s"}
              background={color}
              backgroundHover={color}
              color={textColor}
              loadingColor={textColor}
              onClick={finalToProfile}
              height={"40px"}
              fontSize="14px"
            >
              Complete Your Profile
            </CButtonTwo>
          </div>
        </div>
      </LoginChildSec>
      {/* </CModalTwo> */}
    </>
  );
}
