import { useEffect, useRef } from 'react'
import { VideoStreamType } from '../../../models'
import { SelectedPeerStream } from '../../../controls'
import { isDesktop, isTablet } from '../../../utils/detect-screen'

interface PeerVideoProps {
  isSelfScreenStream?: boolean
  isSelfVideo?: boolean
  numberOfVideos: number
  onVideoClick?: (
    userId: string,
    videoStreamType: VideoStreamType,
    videoStream: MediaStream
  ) => void
  selectedPeerStream: SelectedPeerStream | null
  userId: string
  videoStream: MediaStream
  videoStreamType: VideoStreamType
}

export const PeerVideo = ({
  isSelfScreenStream,
  isSelfVideo,
  numberOfVideos,
  onVideoClick,
  userId,
  selectedPeerStream,
  videoStream,
  videoStreamType,
}: PeerVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const { current: video } = videoRef
    if (!video) return

    video.autoplay = true
    video.srcObject = videoStream

  }, [videoRef, videoStream])


  const handleVideoClick = () => {
    onVideoClick?.(userId, videoStreamType, videoStream)
  }

  return (
    <video
      playsInline
      muted={isSelfScreenStream}
      ref={videoRef}
      onClick={handleVideoClick}
      style={{
        borderRadius: '10px',
        cursor: 'pointer',
        overflow: 'auto',
        marginLeft: selectedPeerStream ? '0' : 'auto',
        marginRight: selectedPeerStream ? '0' : 'auto',
        // margintop: selectedPeerStream ? '10px' : '50px',
        objectFit: 'cover',
        width: '100%',
        height: selectedPeerStream
          ?isDesktop() ?  (window.innerHeight - 195) : (window.innerHeight - 195) / 2 - (isTablet() ? 30 : 20)
          : videoStreamType === 'SCREEN_SHARE' ? 'auto' :'100%',
        ...(isSelfVideo && {
          transform: 'rotateY(180deg)',
        }),
      }}
    />
  )
}
